
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: local('Lato Regular'),
    url('../../public/fonts/Lato-Regular.ttf') format('woff');
  font-display: swap;
}

.font-Lato-Regular {
  font-family: 'Lato' !important;
}

@font-face {
  font-family: 'Lato-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Lato Light'),
    url('../../public/fonts/Lato-Light.ttf') format('woff');
  font-display: swap;
}

.font-Lato-Light {
  font-family: 'Lato-Light' !important;
}
@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: bold;
  src: local('Lato Bold'),
    url('../../public/fonts/Lato-Bold.ttf') format('woff');
  font-display: swap;
}

.font-Lato-Bold {
  font-family: 'Lato-Bold';
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Regular'),
    url('../../public/fonts/AvenirLTStd-Roman.otf') format('woff');
  font-display: swap;
}

.font-Avenir-Regular {
  font-family: 'Avenir' !important;
}

@font-face {
  font-family: 'Avenir-Book';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Book'),
    url('../../public/fonts/AvenirLTStd-Book.otf') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Avenir-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Avenir Regular'),
    url('../../public/fonts/AvenirLTStd-Black.otf') format('woff');
  font-display: swap;
}

.font-Avenir-bold {
  font-family: 'Avenir-Bold' !important;
}


@font-face {
  font-family: 'Roboto-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Regular'),
    url('../../public/fonts/Roboto-Regular.ttf') format('woff');
  font-display: swap;
}



@font-face {
  font-family: 'Roboto-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Bold'),
    url('../../public/fonts/Roboto-Bold.ttf') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Light'),
    url('../../public/fonts/Roboto-Light.ttf') format('woff');
  font-display: swap;
}

/*
.slick-slide:not(.slick-active) {
  opacity: 0.5;
}
.slick-active {
  opacity: 1 !important;
}
.slick-slide{
  opacity: 0.5;
  transition: all 0.5s;
} */

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: black !important;
}
/* input {
  color: red !important;
} */

.search-trigger-icon {
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
}

.search-trigger-icon-open {
  transform: rotate(0.75turn);
}

.search-menu {
  max-height: 0px;
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
}
.search-menu-open {
  max-height: 500px;
}


.auto-transition {
  transition: all 500ms;
}

.margin-left-30 {
  margin-left:  calc(50% - 140px);
  margin-left: -webkit-calc(100%);
  margin-left: -moz-calc(100%);
}

.margin-left-center {
  margin-left: calc(46% - 150px);
  margin-left: -webkit-calc(46% - 150px);
  margin-left: -moz-calc(46% - 150px);
}

.margin-center-0 {
  margin-left: calc(50% - 62px);
  margin-left: -webkit-calc(50% - 62px);
  margin-left: -moz-calc(50% - 62px);
}
.margin-center-hide {
  right: calc(50% - 62px);
  right: -webkit-calc(50% - 62px);
  right: -moz-calc(50% - 62px);
  opacity: 0;
}
.margin-right-30 {
  right: calc(50% - 140px);
  right: -webkit-calc(50% - 140px);
  right: -moz-calc(50% - 140px);
  opacity: 1;
}

.margin-right-center {
  right: calc(46% - 150px);
  right: -webkit-calc(46% - 150px);
  right: -moz-calc(46% - 150px);
  opacity: 1;
}

.menu-item-icon-open {
  transform: rotate(0.75turn);

}

.menu-item-icon {
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
}

.menu-item-icon-v2 {
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
}

.menu-item-icon-open-v2 {
  transform: rotate(0.5turn);
}

.menu-item-content {
  max-height: 0px;
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
}
.menu-item-content-show {
  max-height: 250px;
}
.menu-item-content-show-long {
  max-height: 3000px;
}

.current-bar {
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
  opacity: 0;
}

.is-current > a > .current-bar {
  opacity: 1;
}

.botyp-back {
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);

}

.botyp-check {
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1) !important;
  width: 0px !important;
  /* max-width: 0px; */
}

.botyp-check-show {
  width: 1.2rem !important;
  transform: rotate(360deg);
}

.react__slick__slider__parent {
  position: relative;
}

.react__slick__slider__parent .slick-prev,
.react__slick__slider__parent .slick-next {
  position: absolute;
  top: 50%;
}
.react__slick__slider__parent .slick-prev {
  color: black;
  z-index: 1;
}
.react__slick__slider__parent .slick-next {
  /* border-radius: 50%; */
  color: black;
  z-index: 1;
}

.react__slick__slider__parent .slick-prev:before, .slick-next:before {
  display: none;
}


.searchlg-trigger-icon {
  transform: rotate(0.25turn);
  transition: all 500ms cubic-bezier(0.87, 0, 0.13, 1);
}

.searchlg-trigger-icon-open {
  transform: rotate(0.75turn);
}

.viewmode-item {
  transition: all 200ms ;
}

.viewmode-active {
  background-color: #83B898;
}

h1, h2, h3, h4, h5, h6, p {
  font-family: 'Lato';
}
.leaflet-div-icon {
  background: #fff0 !important;
  border: 0px solid #666 !important;
}

.slick-dots {
  bottom: -15px !important;
}

.zoom-in-wrap:hover .zoom-in {
  transform: scale(1.07);
  transition: all 0.3s ease-in-out;
}

.grecaptcha-badge {
  display: none;
}


.cross-browser-height-1 {
  height: calc(100%);
  height: -webkit-calc(100%);
  height: -moz-calc(100%);
}

.cross-browser-height-2 {
  height: calc(100vh - 50px);
  height: -webkit-calc(100vh - 50px);
  height: -moz-calc(100vh - 50px);
}

.cross-browser-width-1 {
  width: calc(100% - 500px);
  width: -webkit-calc(100% - 500px);
  width: -moz-calc(100% - 500px);
}

.cross-browser-width-2 {
  width: calc(100% - 600px);
  width: -webkit-calc(100% - 600px);
  width: -moz-calc(100% - 600px);
}

button:hover {
  background-color: white;
  color: black;
}

ul {
  list-style-position: outside;
  margin: 0;
}

.black-text-wrapper:hover  p {
  color: black !important;
}

.bar-of-progress {
  z-index: 5555;
}

a {
  color: #4c735c;
}

.youTubeVideoOuterContainer {
  max-width: 900px;
}

.youTubeVideoContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  margin-bottom: 1rem;
}

.youTubeVideo {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.float-right {
  float: right;
  margin-left: 1rem;
}

.float-left {
  float: left;
  margin-right: 1rem;
}

.twoColumn .ctaBlock p {
  font-family: Lato;
  font-size: 18px;
}

.twoColumn .ctaBlock button p:first-child {
  font-size: 17px;
}

.twoColumn .ctaBlock p:first-child {
  font-family: Avenir;
  font-size: 36px;
}

.twoColumn .ctaBlock p:nth-child(2) {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.desktop {
  display: none;
}
.mobile {
  display: block;
}

@media (min-width: 1200px) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}

.gdpr-msg {
  display: flex;
  position: fixed;
  z-index: 10000;
  bottom: 1rem;
  left: 1rem;
  background: #fff;
  border-radius: 5px;
  width: 30rem;
  max-width: calc(100% - 2rem);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  align-items: center;
  padding: 1rem;
}

.gdpr-msg p {
  margin: 0;
}

.gdpr-msg button,
.gdpr-msg button:hover {
  background: #FBEBAA !important;
  border: 0;
  outline: none;
  padding: .5rem;
  border-radius: 4px;
  cursor: pointer;
}

.youTubeVideo--title {
  font-family: Roboto-Bold, sans-serif;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 15px 24px 15px 0;
}

@media (min-width: 600px) {
  .youTubeVideo--title {
    font-family: 'Avenir-Bold', sans-serif;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 1rem
  }

  .youTubeVideo--wrapper {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
  }
}

.youTubeVideo--video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

/* husfoto */
.husfoto360--title {
  font-family: Roboto-Bold, sans-serif;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  margin: 15px 24px 15px 0;
}

@media (min-width: 600px) {
  .husfoto360--title {
    font-family: 'Avenir-Bold', sans-serif;
    font-size: 24px;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 1rem
  }

  .husfoto360--wrapper {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
  }
}

.husfoto360--video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.hero a {
  color: #C02D7C;
  font-weight: 900;
  text-decoration: none;
}

.hero a:hover {
  text-decoration: underline;
}
